/**
 * 获取随机字符串
 * @param { Number } length 随机字符串长度
 */
export const getRandomStr = (length = 32) => {
  const charts =
    "0123456789abcdefghigklmnopqrstuvwxyzABCDEFGHIGKLMNOPQRSTUVWXYZ";
  const maxPos = charts.length;
  let result = "";
  for (let i = 0; i < length; i++) {
    result += charts.charAt(Math.floor(Math.random() * maxPos));
  }
  return result;
};

/**
 * 字符串转成Hex
 * @param { String } 需要转化的字符串
 */
export const toHex = (str = "") => {
  if (!str) return "";
  const hexCharCodes = [];
  for (let i = 0; i < str.length; i++) {
    hexCharCodes.push(str.charCodeAt(i).toString(16));
  }
  return hexCharCodes.join("");
};

/**
 * 生成通用deviceId
 * @param { String } str
 */
export const getGenerateDeviceId = (str = "") => {
  let sum = 0;
  for (let i = 0; i < str.length; i++) {
    sum += str[i].charCodeAt(0);
  }
  const res = (sum % 256 ^ 0xff).toString(16);
  return res?.length === 1 ? 0 + res : str + res;
};

/**
 * 默认生成一个设备Id
 * @param { Number } 需要生成id的长度
 */
export const getDeviceId = (length = 16) => {
  return getGenerateDeviceId(toHex(getRandomStr(length)));
};

/**
 * 随机生成一个uuid（随机生成字符串格式有要求？所以又重写一个备用）
 */
export const generateUUID = () => {
  let d = new Date().getTime();
  const uuid = "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx".replace(
    /[x]/g,
    function (c) {
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
};

/**
 * 解析浏览器携带的参数
 */
export const decodeQuery = () => {
  const href = window.location.href;
  const queryData = {};
  const url = new URL(href);
  const params = new URLSearchParams(url.search);
  for (let key of params.keys()) {
    queryData[key] = params.get(key);
  }
  return queryData;
};

/**
 * 是否长按
 */
export const setupLongPress = (element, callback) => {
  let timer;

  element.addEventListener("touchstart", function (e) {
    e.preventDefault();
    // 设置计时器，如果在300ms内松开了鼠标，则认为是点击，否则是长按
    timer = setTimeout(function () {
      callback(e);
    }, 300);
  });

  element.addEventListener("touchend", function () {
    clearTimeout(timer);
  });
};

 // 处理channel逻辑
 export const cutChannel = (channel) => {
  if (!channel) return "other_other_other";

  let parts = channel.split('_'); // 使用下划线进行分割
  parts.push('other');
  parts.push('other');
  parts.push('other');
  
  let result = parts.slice(0, 3).join('_'); // 取出前3个部分并拼接
  return result;
};


/**
 * 获取当前环境
 */
export const getCurrentEnv = () => {
  const { host } = window.location;
  if (host.includes("test")) {
    return "test";
  } else if (host.includes("www.51xgcs.com")) {
    return "prod";
  } else if (host.includes("localhost")) {
    return "dev";
  } else if (host.includes("preonline.51xgcs.com")) {
    return "preonline";
  }
  return "prod";
};

/**
 * 环境对应接口域名
 */
export const ENV_API_DOMAIN_MAP = {
  dev: "",
  test: "https://test.api.51xgcs.com",
  // test: '',
  prod: "https://api.51xgcs.com",
  preonline: "https://api.51xgcs.com",
};

/**
 * 环境对应打点域名
 */
export const ENV_DATA_DOMAIN_MAP = {
  dev: "https://test.data.51xgcs.com",
  test: "https://test.data.51xgcs.com",
  // test: '',
  prod: "https://data.51xgcs.com",
  preonline: "https://data.51xgcs.com",
};

/**
 * 媒体端服务
 */
export const ENV_MEDIA_DOAMIN_MAP = {
  dev: "",
  // test: 'http://test.media.51xgcs.com',
  test: "",
  prod: "https://media.51xgcs.com",
  preonline: "https://media.51xgcs.com",
};

/**
 * 返回各个环境对应的域名
 */
export const getDomain = () => {
  let env = getCurrentEnv();
  //env='prod'
  return {
    api: ENV_API_DOMAIN_MAP[env],
    data: ENV_DATA_DOMAIN_MAP[env],
  };
};

/**
 * 判断是否为移动端
 */
export const isMobile = () => {
  const mobileKeywords = [
    "Mobile",
    "Android",
    "iPhone",
    "iPad",
    "Windows Phone",
    "BlackBerry",
    "Symbian",
    "Opera Mobi",
    "Maemo",
    "Meego",
    "Nintendo DS",
    "Nintendo",
    "PSP",
    "Kindle",
    "PlayStation",
  ];

  for (let i = 0; i < mobileKeywords.length; i++) {
    const keyword = mobileKeywords[i];
    const regex = new RegExp(keyword, "i");
    if (regex.test(userAgent)) {
      return true;
    }
  }
  return false;
};

const userAgent = navigator.userAgent;
const osMatch =
  userAgent.match(
    /(ipad|iphone|android|macintosh|windows|openharmony|harmonyos)/i
  ) || [];
const os = osMatch[1] || "";

export const getOS = () => {
  return os.toLowerCase();
};

export const isPhone = () => {
  return ["ipad", "iphone"].includes(getOS());
};
/**
 * 处理支付宝渠道跳转
 */
export const onAlink = ({ from, to, navigate, options = {} }) => {
  navigate && navigate(to, options);
};

/**
 * 判断是否来源alink
 */
// export const isAlink = (pathname) => {
//   return true;// pathname?.startsWith?.('/alink')
// }

export const getTimeZone = () => {
  return parseInt((0 - new Date().getTimezoneOffset()) / 60);
};

// 复制文字到剪切板
export const copyTextToClipboard = async (text) => {
  if (window.isSecureContext && navigator.clipboard) {
    try {
      await navigator.clipboard.writeText(text);
      return true;
    } catch (err) {
      return false;
    }
  } else {
    const copyElem = document.createElement("textarea");
    let styles = copyElem.style;
    styles.position = "fixed";
    styles.zIndex = "0";
    styles.left = "-500px";
    styles.top = "-500px";
    copyElem.value = text;
    document.body.appendChild(copyElem);
    copyElem.focus();
    copyElem.select();
    let result = false;
    result = document.execCommand("copy");
    if (result) {
      copyElem.remove();
      return true;
    } else {
      return false;
    }
  }
};

// 通过paper_id获取产品类型和puid、product,
export const getProductType = (paper_id) => {
  // MBTI("mbti", "MBTI性格测试"),
  // ABO("abo", "ABO性别测试"),
  // ANIMAL( "animal", "内心动物原型测试"),
  // LOVE( "love", "恋爱脑测试"),
  // GIVP_PRODUCT( "give_product", "权益类产品测试")
  switch (paper_id) {
    case "240629162214593PAPER004201":
    case "240629162214593PAPER004202":
    case "240629162214593PAPER004203":
      return { type: "mbti", puid: "240629162214593PU004201", product: "0" };
    case "240629162214593PAPER004204":
      return { type: "abo", puid: "240629162214593PU004202", product: "1" };
    case "240629162214593PAPER004205":
      return { type: "animal", puid: "240629162214593PU004203", product: "2" };
    case "240629162214593PAPER004206":
      return { type: "love", puid: "240629162214593PU004204", product: "3" };
    default:
      return "";
  }
};

// 根据产品类型获取puid,赠送的题型paper_id
export const getPuidByType = (type) => {
  switch (type) {
    case "mbti":
      return {
        puid: "240629162214593PU004201",
        paper_id: "240629162214593PAPER004203",
        product: "0",
      };
    case "abo":
      return {
        puid: "240629162214593PU004202",
        paper_id: "240629162214593PAPER004204",
        product: "1",
      };
    case "animal":
      return {
        puid: "240629162214593PU004203",
        paper_id: "240629162214593PAPER004205",
        product: "2",
      };
    case "love":
      return {
        puid: "240629162214593PU004204",
        paper_id: "240629162214593PAPER004206",
        product: "3",
      };
    default:
      return "";
  }
};

export const  getHashCode = (str) => {
    if(!str) {
        return 0
    }
    let hashcode2 = 0
    for(let i=0;i<str.length;i++){
      //溢出需要每次运算后立即处理，否则可能超过js数值的表示范围。
        hashcode2 = hashcode2*31+str.charCodeAt(i)
        hashcode2 &= 0xffffffff
    }
    return hashcode2
}

export const randomBySeed = (str) => {

  let seed = Math.abs(getHashCode(str))
  seed=(seed * 9301 + 49297) % 233280; 
  return seed / 233280.0; 

}

export const getImportantParams=(searchParams)=>{
 
  let params="";
  if(searchParams.get('channel')){
    params=`&channel=${searchParams.get('channel')}`
  }
  if(searchParams.get('media')){
    params=`${params}&media=${searchParams.get('media')}`
  }
  if(searchParams.get('clickid')){
    params=`${params}&clickid=${searchParams.get('clickid')}`
  }
  if(searchParams.get('bd_vid')){
    params=`${params}&bd_vid=${searchParams.get('bd_vid')}`
  }
  if(searchParams.get('qz_gdt')){
    params=`${params}&qz_gdt=${searchParams.get('qz_gdt')}`
  }
  return params;
}

export const logi=(content)=>{
  const currentTime = new Date().toISOString();
  console.log(currentTime,content);
}
